var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDisplay === true ? _vm.listData && _vm.listData.length > 0 : true)?_c('div',{ref:"desc",staticClass:"channel_wrap"},[(_vm.name)?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),(!_vm.isHtml)?_c('div',{staticClass:"list_wrap",style:({ 'max-height': _vm.status ? _vm.textHeight : '', opacity: _vm.opacity ? 1: 0 })},[_c('span',{staticClass:"list_all",class:[_vm.chooseArr.indexOf(999) >-1 ?
      'choose_class': '', 'list_name'],on:{"click":function($event){return _vm.chooseHandle()}}},[_vm._v("全部")]),_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.chooseHandle(item)}}},[_c('span',{class:[_vm.chooseArr.indexOf(item[_vm.valueName]) >-1 ?
        'choose_class': '', 'list_name']},[_vm._v(" "+_vm._s(item[_vm.labelName])+" ")])])}),(_vm.isShow)?_c('div',{staticClass:"show_all mt5",on:{"click":_vm.btnHandle}},[_vm._v(" "+_vm._s(_vm.status ? '展开' : '收起')+" "),_c('i',{class:_vm.status ? 'el-icon-arrow-down' : 'el-icon-arrow-up'})]):_vm._e()],2):_c('div',{staticClass:"list_wrap"},[(_vm.isShow)?_c('div',{style:({'line-clamp':!_vm.status ? _vm.linesNum : '',
                  'display': !_vm.status ? '-webkit-box' : '',
                  'overflow': 'hidden',
                  'text-overflow': 'ellipsis',
                  '-webkit-box-orient': 'vertical'}),domProps:{"innerHTML":_vm._s(_vm.htmlData)},on:{"click":_vm.htmlDataHandle}}):_c('div',{style:({'line-clamp': _vm.status ? _vm.linesNum : '',
                  'display': _vm.status ? '-webkit-box' : '',
                  'overflow': 'hidden',
                  'text-overflow': 'ellipsis',
                  '-webkit-box-orient': 'vertical'}),domProps:{"innerHTML":_vm._s(_vm.htmlData)},on:{"click":_vm.htmlDataHandle}}),(_vm.isShow)?_c('div',{staticClass:"show_all text_btn",on:{"click":_vm.btnHandle}},[_vm._v(" "+_vm._s(!_vm.status ? '阅读全文' : '收起')+" "),_c('i',{class:!_vm.status ? 'el-icon-arrow-down' : 'el-icon-arrow-up'})]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }