<template>
  <div class="question-list">
    <div class="demo-image__preview">
      <el-image
        ref="previewzRef"
        :src="previewUrl"
        :preview-src-list="srcList"
        style="width: 0; height: 0;"
      />
    </div>
    <h2 class="title header_wrap">
      知识管理
      <div class="btn_list_wrap">
        <div v-for="(item, index) in downloadArr" :key="index">
          <el-button
            v-if="item.id !== 3 || (item.id === 3 && checkPermission('KFXT_ZSK_ZSKBJ'))"
            :type="item.type"
            @click="search(item.id)"
          >
            <i v-if="item.icon" class="el-icon-plus" />{{ item.name }}
          </el-button>
        </div>
      </div>
    </h2>
    <div class="scroll_wrap">
      <div class="search_mes_wrap">
        <div class="search_wrap">
          <div class="search_input">
            <p class="search_name">内容搜索：</p>
            <el-input
              v-model="params.titles"
              @keyup.enter.native="searchEnterFun"
              type="text"
              maxlength="20"
              width="200"
              suffix-icon="el-icon-search"
              placeholder="请输入问题标题/内容/编号进行搜索"
            />
          </div>
          <div class="btn_list_wrap">
            <div v-for="(item, index) in searchBtnArr" :key="index">
              <el-button
                v-if="item.id !== 3 || (item.id === 3 && checkPermission('KFXT_ZSK_ZSKBJ'))"
                :type="item.type"
                @click="search(item.id)"
              >
                <i v-if="item.icon" class="el-icon-plus" />
                {{ item.name }}
              </el-button>
            </div>
          </div>
        </div>
        <div class="select-wrapper">
          <div @click="btnHandle" class="show_all_selected">
            {{ !showAllSelect ? '展开全部' : '收起全部' }}
            <i :class="!showAllSelect ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" />
          </div>
          <subject-choose
            v-model="params.channelCode"
            @change="changeChannel"
            name="业务来源："
            lines-num="1"
            label-name="channelName"
            value-name="channelCode"
            action-name="problemOrginList"
          />

          <div v-show="showAllSelect" class="in-wrapper">
            <subject-choose
              v-model="params.channelCode2"
              :arg="params.channelCode"
              :is-switch="isSwitch"
              @change="changeChannelCode2"
              name="二级来源："
              label-name="channelName"
              value-name="channelCode"
              action-name="getOriginList"
            />

            <subject-choose
              v-model="params.channelCode3"
              :arg="params.channelCode2"
              :arg2="params.channelCode"
              :is-switch="isSwitch"
              :is-display="true"
              @change="changeChannelCode3"
              name="三级来源："
              label-name="channelName"
              value-name="channelCode"
              action-name="getOriginList"
            />

            <subject-choose
              v-model="params.classificaCode"
              @change="classificaCodeChange"
              name="问题分类："
              label-name="classificaName"
              value-name="classificaCode"
              action-name="classificaPageList"
            />

            <subject-choose
              v-model="params.label"
              @change="labelChange"
              name="业务标签："
              sate-name="labelArr"
            />
          </div>
        </div>
      </div>

      <div class="list_wrap_all">
        <div class="btn_list_wrap">
          <el-checkbox v-model="allChecked" @change="allCheckedHandle"> 全选 </el-checkbox>
          <div v-for="(item, index) in btnArr" :key="index">
            <el-button
              v-if="
                (item.id !== 3 && item.id !== 9) ||
                (item.id === 3 && checkPermission('KFXT_ZSK_ZSKBJ')) ||
                (item.id === 9 && checkPermission('KFXT_ZSK_ZSKPLSC'))
              "
              :type="item.type"
              :style="{ color: item.color, border: `1px solid ${item.color}` }"
              @click="search(item.id)"
            >
              <i v-if="item.icon" class="el-icon-plus" />
              {{ item.name }}
            </el-button>
          </div>
        </div>
        <div class="list_wrap_div">
          <div v-for="(item, index) in dataList" :key="index" class="list_wrap">
            <div class="list_checked">
              <el-checkbox v-model="item.checked" @change="checkedChange" />
            </div>
            <div class="left_wrap">
              <div class="left_des">
                <!-- <el-checkbox v-model="item.checked" />
                                {{ item.checked }} -->
                <span @click="lookHandle(item.id)" class="title_code">
                  {{ item.titleCode }}
                </span>
                <subject-choose
                  :is-html="2"
                  :othor-param="item"
                  :html-data="item.title"
                  @htmlDataHandle="htmlDataHandle"
                  lines-num="1"
                />
              </div>

              <subject-choose :is-html="2" :html-data="item.reply" lines-num="3" />
              <div class="bottom_wrap">
                <p class="gmt_modified">
                  来源：
                  <span v-html="item.channelName" class="mr5" />

                  <span v-html="item.classificaName" class="mr5" />

                  <span v-html="item.labelName" />
                </p>
                <div class="bottom_right">
                  <p>
                    最近编辑时间：
                    {{ formatDateFn(item.gmtModified) }}
                  </p>
                  <p class="user_name">最近编辑人：{{ item.modifyUser || item.createUser }}</p>
                </div>
              </div>
            </div>
            <div class="btn_wrap">
              <span v-if="checkPermission('KFXT_ZSK_ZSKZD')" @click="topHandle(item)" class="edit">
                {{ !item.gmtTop ? '置顶' : '取消置顶' }}
              </span>
              <span v-if="checkPermission('KFXT_ZSK_ZSKZD')" class="line" />
              <span
                v-if="checkPermission('KFXT_ZSK_ZSKBJ')"
                @click="editHandle(item.id)"
                class="edit"
              >
                编辑
              </span>
              <span v-if="checkPermission('KFXT_ZSK_ZSKBJ')" class="line" />
              <span
                v-if="checkPermission('KFXT_ZSK_ZSKFZ')"
                @click="editHandle(item.id, 'copy')"
                class="edit"
              >
                复制
              </span>
              <span v-if="checkPermission('KFXT_ZSK_ZSKFZ')" class="line" />
              <span
                v-if="checkPermission('KFXT_ZSK_ZSKSC')"
                @click="deleteHandle(item.id)"
                class="delete"
              >
                删除
              </span>
            </div>
          </div>
          <div v-if="dataList.length === 0 && !loading" class="not_data">暂无数据</div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <template>
        <el-backtop>
          Scroll down to see the bottom-right button.
          <i class="el-icon-caret-top" />
        </el-backtop>
      </template>

      <el-pagination
        :current-page.sync="params.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="params.pageSize"
        :total="Number(totalNumber)"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>

    <el-dialog :visible.sync="showDialog" title="导入EXCEL" width="560px">
      <div class="form-item">
        <el-form label-width="140px" class="file_wrap">
          <el-form-item label="导入文件名：">
            <div style="display: flex;">
              <span class="file_name">
                {{ sourceData.fileName }}
              </span>
              <el-upload
                :show-file-list="false"
                :http-request="upload"
                action="#"
                accept=".xls,.xlsx"
              >
                <el-button size="small" type="primary"> 导入EXCEL </el-button>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="备注：" prop="channelName">
            <el-input
              v-model="sourceData.remarks"
              width="100"
              type="textarea"
              maxlength="10"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="内部编号重复时：">
            <el-radio-group v-model="sourceData.judgeInternalCode" label="导入文件名：">
              <el-radio :label="1"> 覆盖原内容 </el-radio>
              <el-radio :label="2"> 不导入 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="explain">
          <p>说明：</p>
          <p>1.支持后缀为xls和xlsx的excel文件导入</p>
          <p>2.支持一个文件一个工作表,工作表必须包括表头,且在第一行；</p>
          <p>3.导入是异步处理的,提交导入后可以立刻进行其他的导入,服务器会排队处理</p>
          <p>4.单次最多只能导入1000条</p>
          <p>5.来源名称、分类名称、标签和已有的名称重复时，不创建；</p>
          <p>6.以内部编号为唯一校验，当多个内部编号重复时，只导入其中一个；</p>
        </div>
        <div class="import_btn">
          <el-button @click="recordListHandle" size="small" type="primary"> 导入 </el-button>
        </div>
      </div>
    </el-dialog>
    <div v-if="isScrollTop" @click="scrollTopHandle" class="top">
      <i class="el-icon-caret-top" />
    </div>
  </div>
</template>

<script>
import { Backtop, Message } from 'element-ui';
import { mapActions, mapGetters } from 'vuex';
import { question } from '@/api';
import { formatDate, analysisImg, keywordChangeColor } from '@/utils/util';
import subjectChoose from '@/components/subjectChoose';

export default {
  components: {
    subjectChoose,
    elBacktop: Backtop,
  },
  data() {
    return {
      searchBtnArr: [
        { id: 1, name: '查询', type: 'primary' },
        { id: 2, name: '重置' },
      ],
      btnArr: [
        {
          id: 9,
          name: '删除',
          color: '#00b6ff',
        },
        {
          id: 3,
          name: '创建',
          type: 'primary',
          icon: true,
        },
        {
          id: 4,
          name: '下载导入模版',
          color: '#00b6ff',
        },
        {
          id: 5,
          name: '导入',
          color: '#00b6ff',
        },
        {
          id: 6,
          name: '导出',
          color: '#00b6ff',
        },
      ],
      downloadArr: [
        { id: 7, name: '导入记录', type: 'primary' },
        { id: 8, name: '导出记录', type: 'primary' },
      ],
      sourceData: {
        fileName: '',
        url: '',
        file: '',
        judgeInternalCode: 1,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        channelCode: '',
      },
      dataList: [],
      totalNumber: '',
      previewUrl: null,
      srcList: [],
      showDialog: false,
      loading: false,
      queryParams: {},
      searchNameParams: {},
      fileMes: '',
      exportParams: {},
      isSwitch: true,
      allChecked: false,
      isScrollTop: false,
      showAllSelect: true,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  created() {
    window.previewHandle = this.previewHandle;
    const questionsSearch = sessionStorage.getItem('questionsSearch');
    if (questionsSearch) {
      this.params.pageNum = Number(JSON.parse(questionsSearch).pageNum);
    }
    this.listenerFunction();
  },
  mounted() {
    const questionsSearch = sessionStorage.getItem('questionsSearch');

    /**
     * 本地有搜索记录根据搜索记录查询
     * @param { Object } params 搜索参数
     * @param { Object } queryParams 选择搜索条件后中文搜索条件
     * @param { Object } searchNameParams 点击搜索后中文参数
     */
    if (questionsSearch) {
      const searchObject = JSON.parse(questionsSearch);
      this.params = searchObject;
      this.searchNameParams = searchObject;
      this.queryParams = searchObject;
      this.isSwitch = false;
      // sessionStorage.removeItem('questionsSearch');
    }
    this.init();
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.listenerFunction);
  },
  methods: {
    ...mapActions('question', [
      'deleteProblem',
      'downloadTempUrl',
      'exportFile',
      'importFile',
      'topProblem',
      'deleteProblemBatch',
    ]),
    listenerFunction() {
      document.addEventListener('scroll', this.handleScroll, true);
    },
    handleScroll() {
      const { clientHeight, scrollTop } = document.querySelector('.scroll_wrap') || {};
      if (scrollTop > clientHeight) {
        this.isScrollTop = true;
      } else {
        this.isScrollTop = false;
      }
    },
    scrollTopHandle() {
      document.querySelector('.scroll_wrap').scrollTop = 0;
    },
    checkedChange() {
      let flag;
      this.dataList.forEach(res => {
        if (!res.checked) {
          flag = true;
        }
      });
      this.allChecked = !flag;
    },
    allCheckedHandle() {
      // this.allChecked = !this.allChecked;
      this.dataList = this.dataList.filter(res => {
        const dt = res;
        dt.checked = this.allChecked;
        return dt;
      });
    },
    searchEnterFun(e) {
      const keyCode = window.event ? e.keyCode : e.which;
      if (keyCode === 13) {
        this.init();
      }
    },
    formatDateFn(v) {
      return formatDate(new Date(v), 'yyyy-mm-dd HH:ii:ss');
    },

    handleSizeChange(val) {
      this.params.pageSize = val;
      this.params.pageNum = 1;
      this.init();
      sessionStorage.setItem(
        'questionsSearch',
        JSON.stringify({ ...this.searchNameParams, ...this.params }),
      );
    },

    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.init();
      sessionStorage.setItem(
        'questionsSearch',
        JSON.stringify({ ...this.searchNameParams, ...this.params }),
      );
    },
    /**
     * 查询列表数据
     * @param { function } analysisImg 将图片转换为指定文字
     * @param { function } keywordChangeColor 关键字高亮
     * @param { } channelCode 拼接渠道
     */
    init() {
      const { channelCode, channelCode2, channelCode3 } = this.params;
      this.exportParams = this.params;
      let code = channelCode;
      if (channelCode && channelCode2) {
        code = `${channelCode2}`;
      }
      // 增加三级渠道判断，逻辑写的不好
      if (channelCode && channelCode2 && channelCode3) {
        code = `${channelCode3}`;
      }
      this.loading = true;
      question
        .callSearchProblemList({
          ...this.params,
          channelCode: code,
        })
        .then(res => {
          if (res?.code === '0') {
            const { list = [], totalNumber } = res.data || {};
            const { titles } = this.params;
            // 解决字数判断显示阅读全文
            this.dataList = [];
            list.forEach(dt => {
              const item = dt;
              const { labelName, title, reply, channelName, classificaName } = item;
              const {
                channelName: channelNames,
                channelName2,
                channelName3,
                labelName: sLabelName,
                classificaName: sClassificaName,
              } = this.searchNameParams;
              item.labelName = keywordChangeColor(labelName, titles);
              item.reply = keywordChangeColor(analysisImg(reply), titles);
              item.title = keywordChangeColor(title, titles);
              item.channelName = keywordChangeColor(channelName, channelNames);
              item.classificaName = keywordChangeColor(classificaName, sClassificaName);
              item.labelName = keywordChangeColor(labelName, sLabelName);
              if (channelName2) {
                item.channelName = keywordChangeColor(
                  channelName,
                  `${channelNames}-${channelName2}`,
                );
              }
              if (channelName3) {
                item.channelName = keywordChangeColor(
                  channelName,
                  `${channelNames}-${channelName2}-${channelName3}`,
                );
              }
            });
            list.forEach(result => {
              const dt = result;
              dt.checked = false;
            });
            this.$nextTick(() => {
              this.totalNumber = totalNumber;
              this.dataList = list;
              this.loading = false;
            });
          }
        });
    },

    /**
     * 操作按钮 1:查询 2:重置 3:创建 4: 下载导入模版
     */
    search(id) {
      switch (id) {
        case 1:
          this.init();
          this.exportParams = this.params;
          this.searchNameParams = JSON.parse(JSON.stringify(this.queryParams));
          sessionStorage.setItem(
            'questionsSearch',
            JSON.stringify({ ...this.searchNameParams, ...this.params }),
          );
          break;
        case 2:
          this.params = {
            pageNum: 1,
            pageSize: 10,
          };
          this.searchNameParams = {};
          this.queryParams = {};
          this.isSwitch = true;
          sessionStorage.removeItem('questionsSearch');
          this.init();
          break;
        case 3:
          this.addHandle();
          break;
        case 4:
          this.downloadTempUrlHandle();
          break;
        case 5:
          this.showDialog = true;
          break;
        case 6:
          this.exportFileHandle();
          break;
        case 7:
          this.recordList(1);
          break;
        case 8:
          this.recordList(2);
          break;
        case 9:
          this.deleteListHandle();
          break;
        default:
      }
    },

    // 3创建
    addHandle() {
      this.$router.push({
        path: '/question/detail',
      });
    },

    // 4下载
    async downloadTempUrlHandle() {
      const dt = await this.downloadTempUrl();
      window.location.href = dt.data.path;
    },

    /**
     * 5: 导入文件
     */
    recordListHandle() {
      if (!this.fileMes) {
        this.$message('请选择导入文件');
        return;
      }
      const { realName } = this.userInfo;
      const { remarks, judgeInternalCode } = this.sourceData;
      const params = new FormData();
      params.append('files', this.fileMes);
      params.append('remarks', remarks || '-');
      params.append('impUser', realName);
      params.append('judgeInternalCode', judgeInternalCode);
      this.importFile(params).then(() => {
        this.$router.push({
          path: '/question/recordList',
          query: {
            operationStatus: 1,
          },
        });
      });
    },
    // 上传图片
    upload(e) {
      this.sourceData.fileName = e.file.name;
      this.fileMes = e.file;
    },

    /**
     * 6:导出拼接导出搜索条件
     * @param { } searchNameParams 点击搜索后导出参数
     */
    exportFileHandle() {
      const { titles } = this.params;
      const { realName } = this.userInfo;
      if (this.dataList.length === 0) {
        this.$message('无导出数据,请重新选择！');
        return;
      }
      const {
        channelName,
        channelName2,
        channelName3,
        classificaName,
        labelName,
      } = this.searchNameParams;
      let exportCondition = '-';
      if (titles) {
        exportCondition = `关键词：${channelName};`;
      }
      if (channelName) {
        exportCondition = `${exportCondition}业务来源：${channelName};`;
      }
      if (channelName2) {
        exportCondition = `业务来源：${channelName}-${channelName2};`;
      }
      if (channelName3) {
        exportCondition = `业务来源：${channelName}-${channelName3};`;
      }

      if (classificaName) {
        exportCondition = `${exportCondition}分类：${classificaName};`;
      }
      if (labelName) {
        exportCondition = `${exportCondition}标签：${labelName};`;
      }
      const { channelCode, channelCode2, channelCode3 } = this.params;
      let code = channelCode;
      if (channelCode && channelCode2) {
        code = `${channelCode2}`;
      }
      if (channelCode && channelCode2 && channelCode3) {
        code = `${channelCode3}`;
      }
      this.exportFile({
        ...this.exportParams,
        exportCondition,
        exportUser: realName,
        channelCode: code,
      });
      setTimeout(() => {
        this.recordList(2);
      }, 500);
    },
    // 业务来源中文名
    changeChannel(v) {
      this.queryParams.channelName = v.channelName;
    },
    // 二级来源中文
    changeChannelCode2(v) {
      this.queryParams.channelName2 = v.channelName;
    },

    // 三级来源中文
    changeChannelCode3(v) {
      this.queryParams.channelName3 = v.channelName;
    },

    // 分类中文
    classificaCodeChange(v) {
      this.queryParams.classificaName = v.classificaName;
    },
    labelChange(v) {
      this.queryParams.labelName = v.label;
    },

    // 7 导入 8 导出列表
    recordList(v) {
      let path = '/question/recordList';
      if (v === 2) {
        path = '/question/exportList';
      }
      this.$router.push({
        path,
        query: {
          operationStatus: v,
        },
      });
    },

    deleteListHandle() {
      const ids = [];
      this.dataList.forEach(res => {
        if (res.checked) ids.push(res.id);
      });
      if (ids.length === 0) {
        Message('请先选择删除项');
      } else {
        this.$confirm('是否确认删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.deleteProblemBatch({
            ids,
          }).then(() => {
            this.params.pageNum = 1;
            setTimeout(() => {
              Message.success('删除成功！');
              this.allChecked = false;
              this.init();
            }, 1000);
          });
        });
      }
    },

    checkPermission(code) {
      return this.$store.state.permissionData.indexOf(code) !== -1;
    },

    /**
     * 点击图片放大
     * @param { string } url 当前点击图片地址
     * @param { } allUrl 所有图片地址 ： url1, url2,url3
     */
    previewHandle(url, allUrl) {
      this.$refs.previewzRef.showViewer = true;
      this.previewUrl = url;
      this.srcList = allUrl.split(',');
    },

    htmlDataHandle(v) {
      this.lookHandle(v.id);
    },
    // 详情
    lookHandle(id) {
      this.$router.push({
        path: '/question/show',
        query: {
          id,
          ...this.searchNameParams,
          titles: this.params.titles,
        },
      });
    },

    async topHandle(item) {
      const { id, gmtTop } = item;
      // const dt = item;
      await this.topProblem({
        id,
        type: gmtTop ? 2 : 1,
      });

      // dt.gmtTop = gmtTop ? '' : 2;
      setTimeout(() => {
        this.allChecked = false;
        Message.success(gmtTop ? '取消置顶成功' : '置顶成功');
        this.init();
      }, 2000);
    },

    // 编辑
    editHandle(id, copy) {
      this.$router.push({
        path: '/question/detail',
        query: {
          id,
          ...this.params,
          copy,
        },
      });
    },

    /**
     * 删除
     * @param { Number} id
     */
    deleteHandle(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.deleteProblem({
          id,
        }).then(() => {
          this.params.pageNum = 1;
          setTimeout(() => {
            this.init();
          }, 1000);
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        });
      });
    },
    /**
     * 选择区域展开和收起全部点击事件
     */
    btnHandle() {
      this.showAllSelect = !this.showAllSelect;
    },
  },
};
</script>
<style lang="less" scoped>
.question-list {
  height: 100vh;
  overflow: hidden;
}
.header_wrap {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
  padding: 10px 23px 10px 32px;
}
.search_mes_wrap {
  background: #fff;
  margin: 0 24px;
  padding: 0 32px 20px;
  .search_wrap {
    height: 62px;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px dashed #e9e9e9;
    .search_name {
      width: 80px;
    }
    .search_input {
      width: 600px;
      display: flex;
      align-items: center;
    }
  }
}
.scroll_wrap {
  max-height: calc(100vh - 220px);
  overflow: scroll;
}
.list_wrap_all {
  padding: 0 32px 0;
  background: #fff;
  margin: 20px 24px 0;
  .btn_list_wrap {
    padding: 10px 32px 20px 0;
    align-items: center;
  }
}
.btn_list_wrap {
  display: flex;
}
.list_wrap_div {
  min-height: 500px;
  .list_checked {
    margin-right: 10px;
  }
}
.list_wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  padding: 15px 15px 0 0;
  align-items: baseline;

  .left_wrap {
    flex: 1;
    padding: 10px 40px 10px 0;
    .left_des {
      display: flex;
      cursor: pointer;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      .title_code {
        margin-right: 20px;
      }
    }
  }
  .center_des {
    margin: 10px 0;
  }
  .btn_wrap {
    display: flex;
    width: 210px;
    color: #0090ff;
    justify-content: flex-end;
    .line {
      height: 14px;
      width: 1px;
      background: #d8d8d8;
      margin: 0 5px;
    }

    .edit {
      cursor: pointer;
    }

    .delete {
      cursor: pointer;
    }
  }
  .bottom_wrap {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    .gmt_modified {
      flex: 1;
      margin-right: 10px;
    }
    .bottom_right {
      display: flex;

      .user_name {
        margin-left: 10px;
      }
    }
  }
}
.not_data {
  text-align: center;
  min-height: 400px;
  line-height: 400px;
}
.pagination {
  background: #fff;
  padding: 10px 32px;
  margin: 0 24px;
  display: flex;
  justify-content: flex-end;
}
.demo-image__preview {
  position: absolute;
}
.el-image__error {
  display: none;
}
.file_wrap {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 60px;
  .el-textarea {
    width: 256px;
    height: 60px;
  }
  .file_name {
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 235px;
  }
}
/deep/.el-dialog__body {
  padding: 0;
}
.import_btn {
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding-right: 20px;
}
/deep/.el-dialog__header {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.explain {
  margin: 0 20px;
  color: rgba(0, 0, 0, 0.45);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  padding-top: 25px;
}
.el-button {
  margin-left: 10px;
}
.right-content {
  background: red;
}
.mr5 {
  margin-right: 5px;
}
.top {
  position: fixed;
  right: 30px;
  bottom: 100px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 0.12);
  cursor: pointer;
  z-index: 5;
}
.select-wrapper {
  position: relative;
  .show_all_selected {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    color: #1890ff;
    cursor: pointer;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}
</style>
