/*
 * @Author: shuwen.chen
 * @Email: shuwen.chen@medbanks.cn
 * @Date: 2020-12-22 16:32:39
 * @Last Modified by: shuwen.chen
 * @Last Modified time: 2020-12-31 16:08:15
 * @Description: 平铺选择组件 可控制展开多少行显示展开按钮
 * @Route: Route
 */

<template>
  <div ref="desc"
       v-if="isDisplay === true ? listData && listData.length > 0 : true"
       class="channel_wrap">
    <div v-if="name"
         class="name">
      {{ name }}
    </div>
    <div v-if="!isHtml"
         :style="{ 'max-height': status ? textHeight : '', opacity: opacity ? 1: 0 }"
         class="list_wrap">
      <span :class="[chooseArr.indexOf(999) >-1 ?
        'choose_class': '', 'list_name']"
            @click="chooseHandle()"
            class="list_all">全部</span>
      <div v-for="(item, index) in listData"
           :key="index"
           @click="chooseHandle(item)">
        <span :class="[chooseArr.indexOf(item[valueName]) >-1 ?
          'choose_class': '', 'list_name']">
          {{ item[labelName] }}
        </span>
      </div>
      <div v-if="isShow"
           @click="btnHandle"
           class="show_all mt5">
        {{ status ? '展开' : '收起' }}
        <i :class="status ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" />
      </div>
    </div>
    <div v-else
         class="list_wrap">
      <div v-if="isShow"
           :style="{'line-clamp':!status ? linesNum : '',
                    'display': !status ? '-webkit-box' : '',
                    'overflow': 'hidden',
                    'text-overflow': 'ellipsis',
                    '-webkit-box-orient': 'vertical'}"
           @click="htmlDataHandle"
           v-html="htmlData" />
      <div v-else
           :style="{'line-clamp': status ? linesNum : '',
                    'display': status ? '-webkit-box' : '',
                    'overflow': 'hidden',
                    'text-overflow': 'ellipsis',
                    '-webkit-box-orient': 'vertical'}"
           @click="htmlDataHandle"
           v-html="htmlData" />
      <div v-if="isShow"
           @click="btnHandle"
           class="show_all text_btn">
        {{ !status ? '阅读全文' : '收起' }}
        <i :class="!status ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" />
      </div>
    </div>
  </div>
</template>
<script>
/** @dataSource 传入数据,不传为接口数据
 * @value 传入id数据回显
 */

export default {
  name: 'SubjectChoose',
  props: {
    dataSource: {
      type: Array,
      default: () => null,
    },
    name: {
      type: [Number, String, Array], // 可以多选的时候传递标签的labelID来回显
      default: () => null,
    },
    args: {
      type: Boolean,
      default: false,
    },
    isDisplay: {
      type: Boolean,
      default: false,
    },
    // 接口名字
    actionName: {
      // 判断是标签还是类目
      type: String,
      default: () => 'category',
    },
    isMultiple: {
      // 多选还是单选
      type: Boolean,
      default: () => false,
    },
    // 显示几行
    linesNum: {
      type: [Number, String, Array],
      default: () => 1,
    },
    labelName: {
      type: String,
      default: () => 'label',
    },
    valueName: {
      type: String,
      default: () => 'value',
    },
    sateName: {
      type: String,
      default: () => '',
    },
    isHtml: {
      type: [Number, String, Boolean],
      default: () => false,
    },
    htmlData: {
      type: String,
      default: () => '',
    },
    otherParam: {
      type: Object,
      default: () => { },
    },
    arg: {
      type: String,
      default: () => '',
    },
    arg2: {
      type: String,
      default: () => '',
    },
    value: {
      type: String,
      default: () => '',
    },
    isSwitch: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      listData: [],
      isShow: false,
      textHeight: null,
      status: false,
      opacity: false,
      chooseArr: [],
    };
  },
  watch: {
    // 关联数据变化清空数据 重新获取数据
    arg(v) {
      if (this.isSwitch) {
        this.$emit('input', '');
      }
      this.init(v);
    },
    // 监听一级渠道，发生变化清空listData
    arg2() {
      this.listData = [];
    },
    value(v) {
      if (!v) {
        this.chooseArr = [999];
      } else {
        this.chooseArr = [v];
      }
    },
  },
  mounted() { },
  created() {
    if (!this.isHtml) this.init();
    else this.moreHandle(true);
  },
  methods: {
    /**
         * @description 获取数据控制显示几行
         * @private { actionName } 接口action 名字
         * @param { number } linesNum 显示几行
         * @param { Boolean } isShow 是否显示展开按钮
         * @param { } status 切换展示状态
         * @param {  Boolean } opacity 解决先展示所有视觉体验
         */
    init(v) {
      const { actionName, sateName } = this;
      // 读取state数据
      if (sateName) {
        this.listData = this.$store.state[sateName];
        this.moreHandle();
        return;
      }
      this.$store.dispatch(actionName, { channelCode: v }).then((res) => {
        this.listData = res;
        this.moreHandle();
      });
    },
    // 判断显示更多
    moreHandle(h) {
      const { linesNum } = this;
      this.$nextTick(() => {
        let height = 28;
        if (h) {
          height = 20;
        }
        const twoHeight = height * linesNum;
        this.textHeight = `${twoHeight}px`;
        const curHeight = this.$refs.desc?.offsetHeight;
        if (curHeight && curHeight > twoHeight) {
          this.isShow = true;
          this.status = true;
          this.opacity = true;
        } else {
          this.opacity = true;
          this.isShow = false;
          this.status = false;
        }
      });
    },
    htmlDataHandle() {
      this.$emit('htmlDataHandle', this.otherParam);
    },
    btnHandle() {
      this.status = !this.status;
      this.opacity = 1;
    },
    /**
         * @description 选择数据后样式处理和返回数据
         * @param { number } labelName 数据返回的id
         * @param { string } 数据返回的name
         */
    chooseHandle(v) {
      const { valueName } = this;
      if (!v) {
        this.$emit('input', '');
        this.$emit('change', {});
        this.chooseArr = [999];
        return;
      }
      if (!this.isMultiple) {
        this.chooseArr = [v[valueName]];
      }
      this.$emit('change', v);
      this.$emit('input', v[valueName]);
    },
  },
};
</script>
<style lang="scss" scoped>
.channel_wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  margin-bottom: 20px;
  .name {
    width: 80px;
    padding-top: 5px;
  }
}
.list_wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 100px;
  .list_all {
    cursor: pointer;
    padding: 5px 12px;
  }

  .list_name {
    // padding-right: 20px;
    padding: 5px 12px;
    display: block;
    cursor: pointer;
  }
  .show_all {
    color: #1890ff;
    position: absolute;
    right: 50px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
  }
  .mt5 {
    margin-top: 5px;
  }
  .text_btn {
    right: 10px;
  }
  .choose_class {
    color: #fff;
    background: #1890ff;
    border-radius: 4px;
  }
}
.hidden_class {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}
</style>
